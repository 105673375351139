import './index.scss'

import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import Container from '../Container'
import { phoneFormat } from '../../helpers/format'

const bem = new BEMHelper('contact-card')

export function ContactCardGrid({ children, title }) {
  return (
    <Container large {...bem('grid-wrapper')}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      <div {...bem('grid')}>
        {Children.map(children, child =>
          cloneElement(child, {
            tight: true,
          }),
        )}
      </div>
    </Container>
  )
}

export default function ContactCard({ who, id, ...props }) {
  if (!who && !id) {
    return null
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { relativePath: { regex: "/.*.(?:jpg|png)/" } }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 534, quality: 60) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          employees: allEmployee {
            edges {
              node {
                id
                name
                position
                email
                phone
                office
                userId
                slug
                image {
                  alt
                  image {
                    name
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images: { edges }, employees: { edges: employees } }) => {
        const images = edges.map(({ node: { childImageSharp, name } }) => ({
          name,
          ...childImageSharp,
        }))

        const employee = employees
          .map(({ node }) => node)
          .find(({ userId, slug = '' }) => {
            return (id && userId === id) || (who && slug.startsWith(who))
          })

        if (!employee) {
          return null
        }

        const image =
          images.find(({ name }) => name === employee.image.image.name) || {}

        return (
          <ContactCardTemplate
            fluid={image.fluid}
            alt={employee.image.alt}
            {...employee}
            {...props}
          />
        )
      }}
    />
  )
}

export function ContactCardTemplate({
  fluid,
  name,
  position,
  phone,
  office,
  email,
  tight,
  src,
}) {
  const imageProps = fluid && { fluid }
  return (
    <div {...bem('', { tight })}>
      <figure {...bem('image')}>
        {fluid && (
          <Img
            {...imageProps}
            alt={name}
            {...bem('img')}
            style={{ height: '100%', width: '100%' }}
          />
        )}
        {src && <img src={src} alt={name} />}
        {!fluid && !src && <div {...bem('image-fallback')} />}
      </figure>
      <dl {...bem('details')}>
        <dt {...bem('name')}>{name}</dt>
        <dd {...bem('job-title')}>{position}</dd>
        {office && (
          <dd {...bem('info')}>
            <a href={`tel:${office}`} {...bem('link')}>
              {phoneFormat(office, 'office')}
            </a>
          </dd>
        )}
        {phone && (
          <dd {...bem('info')}>
            <a href={`tel:${phone}`} {...bem('link')}>
              {phoneFormat(phone)}
            </a>
          </dd>
        )}
        <dd {...bem('info')}>
          <a href={`mailto:${email}`} {...bem('link')}>
            {email}
          </a>
        </dd>
      </dl>
    </div>
  )
}

ContactCardTemplate.propTypes = {
  position: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  office: PropTypes.string,
  email: PropTypes.string,
  tight: PropTypes.bool,
  fluid: PropTypes.object.isRequired,
  src: PropTypes.string,
}

ContactCard.propTypes = {
  who: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ContactCardGrid.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string,
  grid: PropTypes.string,
}
