import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'
import { animateFrom, animateTo } from '../../helpers/animate'

const bem = new BEMHelper('image')

const EASING_ENTER = `cubic-bezier(0.165, 0.84, 0.44, 1)`
const EASING_EXIT = `cubic-bezier(0.77, 0, 0.175, 1)`
const ANIMATION_TIME = 400
const KEY_ESC = 27

export default class Image extends Component {
  static propTypes = {
    fluid: PropTypes.object,
    size: PropTypes.string,
    src: PropTypes.string,
    children: PropTypes.any,
    content: PropTypes.any,
    alt: PropTypes.string,
    className: PropTypes.string,
    expandable: PropTypes.bool,
    spaceless: PropTypes.bool,
  }

  static defaultProps = {
    size: 'default',
    expandable: true,
  }

  state = {
    expanded: false,
    animation: null,
    animateOut: false,
  }

  storeFromElement = null
  animateOutTimeout = null

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      this.toggle(false)()
    }
  }

  toggle = value => () => {
    if (this.state.expanded !== value) {
      this.setState(
        { expanded: value, animateOut: value === false },
        this.handleAnimation(value),
      )
    }
  }

  handleAnimation = value => () => {
    clearTimeout(this.animateOutTimeout)

    if (this.imageWrapper && this.modalImage && value) {
      document.body.classList.add('block-scrolling')

      this.storeFromElement = this.imageWrapper.querySelector('img')
      this.setState({
        animation: animateFrom({
          from: this.storeFromElement,
          to: this.modalImage,
        }),
      })
    } else if (this.modalImage && this.storeFromElement && !value) {
      this.setState({
        animation: animateTo({
          from: this.modalImage,
          to: this.storeFromElement,
        }),
      })

      this.animateOutTimeout = setTimeout(() => {
        document.body.classList.remove('block-scrolling')
        this.setState({ animation: null, animateOut: false })
      }, ANIMATION_TIME)
    } else {
      this.setState({ animation: null, animateOut: false })
    }
  }

  renderExpanded () {
    const {
      children,
      content,
      fluid,
      size,
      alt,
      expandable,
      className,
      spaceless,
      src,
      ...props
    } = this.props
    const { expanded, animation, animateOut } = this.state

    if (expanded || animateOut) {
      return (
        <div {...bem('modal')} tabIndex="-1" onClick={this.toggle(false)}>
          <div
            {...bem('expanded-image')}
            ref={ref => {
              this.modalImage = ref
            }}
            style={{
              animation: `${animation} ${ANIMATION_TIME}ms ${
                animateOut ? EASING_EXIT : EASING_ENTER
              } both`,
            }}
          >
            {fluid ? (
              <Img fluid={fluid} alt={alt} {...props} />
            ) : (
              <img src={src} alt={alt} {...props} style={{ width: '100%' }} />
            )}
          </div>

          <button
            type="button"
            aria-label="Close"
            onFocus={this.toggle(false)}
            {...bem('backdrop', { 'animate-out': animateOut })}
          />
        </div>
      )
    }

    return null
  }

  render () {
    const {
      children,
      content,
      fluid,
      size,
      alt,
      expandable,
      className,
      spaceless,
      src,
      ...props
    } = this.props
    const { expanded } = this.state

    const isExpandable = size !== 'large' && expandable
    const ImageWrapper = isExpandable ? 'button' : 'div'
    const wrapperProps = isExpandable
      ? {
        type: 'button',
        onClick: this.toggle(true),
        ref: ref => {
          this.imageWrapper = ref
        },
      }
      : {}

    return (
      <figure {...bem('', { [size]: size, spaceless }, className)}>
        {expanded && (
          <button
            type="button"
            aria-label="Close"
            onFocus={this.toggle(false)}
            {...bem('hidden-toggle')}
          />
        )}

        <ImageWrapper {...bem('wrapper')} {...wrapperProps}>
          {fluid ? (
            <Img {...bem('image')} fluid={fluid} alt={alt} {...props} />
          ) : (
            <img {...bem('image')} src={src} alt={alt} {...props} />
          )}
          {(content || children) && (
            <figcaption {...bem('caption')}>{content || children}</figcaption>
          )}
        </ImageWrapper>

        {this.renderExpanded()}
      </figure>
    )
  }
}
