import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title } from '../../components/Article'
import ContactCard from '../../components/ContactCard'
import Image from '../../components/Image'
import Section from '../../components/Section'

import imageTypography from '../../assets/brandbook/images/typography-weights.svg'
import imageTypography2 from '../../assets/brandbook/images/typography-hierarchy.svg'

export default function Typography ({ data: { hero, ada }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Typography" />

      <Article>
        <Text>
          We use Wigrum as our primary typeface. This is a modern sans-serif
          that works well for both digital and print use. It's easy to read and
          the characteristics of letter shapes and angles harmonize with our{' '}
          <Link to="/brandbook/icons">icons and illustrations</Link>.
        </Text>

        <Title level="2">Usage</Title>
        <Image src={imageTypography} expandable={false} />

        <br />
        <Text>
          We use Wigrum in the following weights: Regular, Italic, Medium,
          Medium Italic, Bold, Bold Italic, Black and Black Italic.
        </Text>
        <Title level="2">Typographic hierarchy</Title>
        <Text>
          We use the following principles to ensure maximum readability and
          visual hierarchy. As a rule of thumb the line height should be set 120
          - 140%, depending on context and amount of text.
        </Text>
        <Title level="3">Headlines</Title>
        <Text>
          All headlines are set in Black Italic. Level 1 is always in uppercase.
        </Text>
        <Title level="3">Subheadings</Title>

        <Text>
          Subheadings should always be 45 - 55% of previous headline level size.
        </Text>

        <Title level="3">Paragraphs</Title>
        <Text>Paragraphs should be 20 - 30% of heading size.</Text>

        <Image src={imageTypography2} expandable={false} />

        <Title>Downloads</Title>
        <Text>
          All font files must be licensed before usage. Please contact{' '}
          <Link to="mailto:ada.jakobsen@ncl.no">Ada</Link> for access.
        </Text>
      </Article>

      <Section>
        <Title>Any questions?</Title>
        <ContactCard who="ada-jakobsen" />
      </Section>
    </>
  )
}

Typography.propTypes = {
  data: PropTypes.object.isRequired,
}

Typography.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/inbetween/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
